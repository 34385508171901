.support {
    background-image: url("../../../public/assets/support.webp");
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    height: 100%;
    min-height: 45vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px 15%;
    box-sizing: border-box;
}

.support-cont {
    width: 100%;
    /* max-width: 800px; */
    display: flex;
    align-items: center;
    gap: 16px;
}

.support-cont h1 {
    color: #F6F6F6;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 2.2rem;
    font-weight: 700;
    text-align: start;
    margin: 0;
    margin-bottom: 12px;
}

.support-cont span {
    color: #4496D2;
}

.support-cont h6 {
    color: #F6F6F6;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    text-align: start;
}

.support-cont button {
    color: #F6F6F6;
    font-size: 1rem;
    font-weight: 600;
    font-family: Quicksand;
    border: none;
    padding: 12px 12px;
    box-sizing: border-box;
    background-color: #4496D2;
    border-radius: 20px;
    width: 160px;
    height: 50px;
    cursor: pointer;
}

.support-cont button:hover {
    opacity: 0.8;
}

@media screen and (max-width:768px) {

    .support {
        padding: 16px 10%;
        box-sizing: border-box;
    }

    .support-cont {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }
}