.about {
    padding: 12px 20px;
    box-sizing: border-box;
    display: flex;
    background: #ECF7FF;
}

.left-about {
    width: 50%;
    height: auto;
}

.left-about-img-cont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-about-img-cont video {
    width: 100%;
    min-width: 220px;
    max-width: 400px;
    height: auto;
}

.left-about-img-cont img {
    width: 100%;
    min-width: 220px;
    max-width: 500px;
}

.right-about {
    width: 50%;
    max-width: 550px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
}

.right-about h5 {
    color: #4496D2;
    text-align: start;
    font-family: 'Schoolbell';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    margin: 0px;
}

.right-about h1 {
    color: #242020;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
    text-align: start;
}

.right-about h6 {
    color: #4496D2;
    text-align: start;
    font-family: Nunito;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    margin: 0px;
}

.right-about p {
    color: #101010;
    text-align: justify;
    font-family: Nunito;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    margin: 0px;
}

@media screen and (max-width:768px) {
    .about {
        padding: 12px 16px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }

    .left-about {
        width: 100%;
        height: 100%;
        min-height: 40vh;
    }

    .right-about {
        width: 100%;
        max-width: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 16px;
    }

}