.joinus {
    background-image: url("../../../public/assets/join us.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    height: 100%;
    min-height: 45vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px 15%;
    box-sizing: border-box;
}

.joinus-cont {
    width: 100%;
    max-width: 600px;
}

.joinus-cont h1 {
    color: #F6F6F6;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 2.2rem;
    font-weight: 700;
    margin: 0;
    text-align: center;
}

.joinus-cont span {
    color: #4496D2;
}

.joinus-cont button {
    color: #F6F6F6;
    font-size: 1rem;
    font-weight: 600;
    font-family: Quicksand;
    border: none;
    padding: 12px 12px;
    box-sizing: border-box;
    background-color: #4496D2;
    border-radius: 20px;
    width: 160px;
    height: 50px;
    cursor: pointer;
}

.joinus-cont button:hover {
    opacity: 0.8;
}

@media screen and (max-width:768px) {

    .joinus {
        padding: 12px 10%;
        box-sizing: border-box;
    }
}