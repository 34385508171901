.blog-detail {
    background-color: #F6F6F6;
}

.bd-header {
    background-image: url("../../../public/assets/blog background.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    height: 100%;
    width: 100%;
    min-height: 45vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 10%;
    box-sizing: border-box;
}

.bd-header h1 {
    color: #F6F6F6;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 2.2rem;
    font-weight: 700;
    margin: 0;
    text-align: center;
}

.bd-header h5 {
    color: #F6F6F6;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    text-align: center;
}

.bd-header span {
    color: #4496D2;
}

.bd-cont {
    display: flex;
    padding: 16px;
    box-sizing: border-box;
}

.right-bd {
    width: 30%;
}

.left-bd {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 16px;
    box-sizing: border-box;
    width: 70%;
}


.bd-headline h1 {
    color: #232323;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 2rem;
    font-weight: 700;
    text-align: start;
    margin: 0;
}

.bd-headline div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10%;
}

.bd-headline span {
    color: #494949;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    text-align: start;
    margin: 0;
}

.blog-detail-img img {
    width: 100%;
}

.bd-text-cont {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.bd-text-cont p {
    color: #101010;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    text-align: start;
    margin: 0;
}

.bottom-bd {
    background-color: #000925;
    padding: 24px 16px;
    box-sizing: border-box;
}

.bottom-bd h6 {
    color: #F6F6F6;
    font-family: 'Schoolbell';
    font-style: normal;
    font-size: 1.1rem;
    font-weight: 500;
    text-align: center;
    margin: 0;
}

.bottom-bd span {
    color: #4496D2;
}


@media screen and (max-width:576px) {
    .bd-cont {
        display: flex;
        flex-direction: column-reverse;
    }

    .right-bd {
        width: 100%;
    }

    .left-bd {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 16px;
        box-sizing: border-box;
        width: 100%;
    }
}