.program {
    padding: 16px 0;
    box-sizing: border-box;
    background-color: #F6F6F6;
}

.top-program {
    background-image: url("../../../public/assets/Group 61.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    height: 100%;
    min-height: 45vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px 15%;
    box-sizing: border-box;
}

.tp-items {
    display: flex;
    justify-content: space-around;
    gap: 8px;
}

.tp-item img {
    width: 50px;
}

.tp-item h2 {
    text-align: center;
    color: #F6F6F6;
    font-family: 'Nunito';
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    margin: 0px;
}

.tp-item h4 {
    text-align: center;
    color: #F6F6F6;
    font-family: 'Nunito';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    margin: 0px;
}

.bottom-program {
    display: flex;
    justify-content: center;
    transform: translateY(-40px);
    z-index: 100;
    padding: 12px;
    box-sizing: border-box;
}

.program-card {
    box-shadow: 0 4px 250px 0 rgba(0, 139, 241, 0.09);
    background-color: #FFF;
    width: 100%;
    max-width: 700px;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
}

.left-program-image {
    width: 30%;
    min-width: 200px;
    max-width: 300px;
}

.left-program-image img {
    width: 100%;
}

.program-days {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
}

.program-days span {
    text-align: center;
    color: #242020;
    font-family: 'Nunito';
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    margin: 0px;
}

.right-program-detail {
    padding: 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
}

.right-program-detail h1 {
    text-align: start;
    color: #242020;
    font-family: 'Nunito';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    margin: 0px;
}

.right-program-detail h6 {
    text-align: start;
    color: #494949;
    font-family: 'Nunito';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    margin: 0px;
}

.program-btn-cont {
    display: flex;
    justify-content: space-between;
}

.prog-raised {
    display: flex;
    align-items: center;
    gap: 6px;
}

.prog-raised img {
    width: 40px;
    height: 40px;
}

.prog-raised span {
    text-align: start;
    color: #4496D2;
    font-family: 'Schoolbell';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    margin: 0px;
}

.prog-raised p {
    text-align: start;
    color: #101010;
    font-family: 'Nunito';
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    margin: 0px;
}

.program-btn-cont button {
    background-color: #4496D2;
    color: #F6F6F6;
    border: none;
    font-size: 0.9rem;
    font-weight: 600;
    font-family: Quicksand;
    padding: 12px 16px;
    box-sizing: border-box;
    border-radius: 20px;
    cursor: pointer;
    height: 45px;
}

.program-btn-cont button:hover {
    opacity: 0.8;
}

@media screen and (max-width:576px) {
    .top-program {
        background-image: url("../../../public/assets/Group 61.webp");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0;
        height: 100%;
        min-height: 45vh;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 16px 10% 40px 10%;
        box-sizing: border-box;
    }

    .tp-items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .program-card {
        box-shadow: 0 4px 250px 0 rgba(0, 139, 241, 0.09);
        background-color: #FFF;
        width: 100%;
        max-width: 700px;
        border-radius: 12px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    .left-program-image {
        width: 100%;
        max-width: none;
    }

    .program-btn-cont {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 8px;
    }
}