.navbar {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    box-sizing: border-box;
    background: #ECF7FF;
    scroll-behavior: smooth;
    /* position: sticky;
    top: 0; */
}

/* Add smooth scrolling animation */
html {
    scroll-behavior: smooth;
}

.left {
    flex: 1;
    /* padding-left: 3%; */
    padding-top: 5px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
}

.navbar .left img {
    width: 140px;
    /* height: 60px; */
}

.navbar .right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 7%;
    font-weight: 400;
    /* padding-right: 3%; */
    box-sizing: border-box;
}

.navbar .right .nav-btn {
    background-color: #4496D2;
    color: #F6F6F6;
    border: none;
    /* color: #232323;
    background-color: #FFFFFF;
    border: 2px solid #223F99; */
    font-size: 0.9rem;
    font-weight: 600;
    font-family: Quicksand;
    padding: 16px 16px;
    box-sizing: border-box;
    border-radius: 20px;
    cursor: pointer;
}

.navbar .right .nav-btn:hover {
    opacity: 0.7;
}

.nav-opt {
    color: #232323;
    font-size: 1rem;
    font-weight: 600;
    font-family: Quicksand;
    cursor: pointer;
}

.nav-fabar {
    display: none;
}


@media screen and (max-width: 768px) {
    .nav-opt {
        display: none;
    }

    .nav-btn {
        display: none;
    }

    .navbar .right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end !important;
        gap: 0;
        font-weight: 400;
        padding-right: 20px;
    }

    .nav-fabar {
        display: block;
        border: none;
        background-color: transparent;
        font-size: 1.5rem;
    }

    .nav-btn-cont-mob {
        display: flex;
        flex-direction: column;
        /* margin-top: 20px; */
    }

    .nav-btn-cont-mob button {
        width: 100%;
        text-align: start;
        padding: 10px 10px;
        box-sizing: border-box;
        color: #232323;
        font-size: 1rem;
        font-weight: 600;
        font-family: Quicksand;
        background-color: transparent;
        border: none;
        /* border-bottom: 1px solid #3b3b3b; */
    }
}