.explore {
    padding: 16px;
    box-sizing: border-box;
    background-color: #F6F6F6;
    /* position: relative; */
}

.explore-header {
    margin-bottom: 5%;
}

.explore-header h6 {
    color: #4496D2;
    text-align: center;
    font-family: 'Schoolbell';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    margin: 0px;
}

.explore-header h1 {
    color: #242020;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
    text-align: center;
}

.explore-cont {
    display: flex;
    align-items: center;
    padding: 0 10%;
    box-sizing: border-box;
}

.left-explore {
    width: 50%;
    max-width: 520px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.left-explore p {
    color: #242020;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1.1rem;
    font-weight: 600;
    text-align: start;
    margin: 0;
    line-height: 2rem;
}

.left-explore button {
    background-color: #4496D2;
    color: #F6F6F6;
    border: none;
    font-size: 0.9rem;
    font-weight: 600;
    font-family: Quicksand;
    padding: 12px 16px;
    box-sizing: border-box;
    border-radius: 20px;
    cursor: pointer;
    width: 160px;
    margin-top: 12px;
}

.left-explore button:hover {
    opacity: 0.7;
}

.right-explore {
    /* position: absolute; */
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 16px; */
    box-sizing: border-box;
}

.explore-slider {
    width: 100%;
    max-width: 500px;
}

.v-slide-card {
    height: 200px;
    width: 100%;
    background: #ECF7FF;
}

.v-slide-card iframe {
    width: 100%;
    height: 100%;
}

.v-slide-card iframe {
    border-radius: 16px;
    overflow: hidden;
    height: 320px;
}

.video-thumbnail{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 16px;
    overflow: hidden;
}

.play-button{
    position: absolute;
    border: none;
    background-color: transparent;
    cursor: pointer;
    /* color: #242020; */
    /* font-size: 1rem; */
}

/* .slick-prev, .slick-next {
    width: 42px;
    height: 42px;
    background-color: #FF5733 !important;
    border-radius: 50%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.slick-prev:before, .slick-next:before {
    font-size: 24px !important;
    color: white !important; 
    opacity: 1 !important; 
} */


@media screen and (max-width:768px) {
    .explore-cont {
        display: flex;
        flex-direction: column;
        padding: 0;
        box-sizing: border-box;
    }

    .left-explore {
        width: 100%;
        max-width: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .right-explore {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 16px;
        box-sizing: border-box;
    }
}