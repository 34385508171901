.dashboard {
    background-color: #FAFAFA;
    min-height: 100vh;
}

.dashboard-header {
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    box-sizing: border-box;
}

.left-dh h1 {
    color: #242020;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0;
    text-align: start;
}

.left-dh h4 {
    color: #242020;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    text-align: start;
}

.right-dh {
    display: flex;
    align-items: center;
    gap: 4px;
}

.right-dh div {
    display: flex;
    flex-direction: column;
}

.right-dh span:first-child {
    color: #242020;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
    text-align: start;
}

.right-dh span:nth-child(2) {
    color: #242020;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    text-align: start;
}

/* main */
.dash-cards-section {
    display: flex;
    /* justify-content: space-between; */
    align-items: flex-start;
    padding: 12px;
    box-sizing: border-box;
    gap: 1rem;
}

.dash-profile-card {
    border-radius: 12px;
    padding: 12px;
    box-sizing: border-box;
    background-color: #EBF7FF;
    height: 160px;
    width: 100%;
    max-width: 320px;
    min-width: 260px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dpc-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.dpc-header h2 {
    color: #242020;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    text-align: start;
    display: flex;
    align-items: center;
    gap: 2px;
}

.dpc-header button {
    color: #3A98D0;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: start;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.dpc-details {
    display: flex;
    align-items: center;
    gap: 4px;
}

.dpc-details div {
    display: flex;
    flex-direction: column;
}

.dpc-details span:first-child {
    color: #242020;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    text-align: start;
}

.dpc-details span:nth-child(2) {
    color: #242020;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 0.9rem;
    font-weight: 300;
    margin: 0;
    text-align: start;
}

.dpc-progress {
    margin-top: 12px;
}

.dpc-progress div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dpc-progress span {
    color: #242020;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 0.8rem;
    font-weight: 300;
}


.dash-my-donation-card {
    border-radius: 12px;
    padding: 12px;
    box-sizing: border-box;
    background-color: #114274;
    height: 160px;
    width: 100%;
    max-width: 320px;
    min-width: 260px;
}

.ddc-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ddc-header h2 {
    color: #FFFFFF;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
    text-align: start;
    display: flex;
    align-items: center;
    gap: 2px;
}

.ddc-header button {
    color: #FFFFFF;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: start;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.ddc-my-donation-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    margin: 8px 0;
}

.ddc-my-donation-detail span {
    color: #FFFFFF;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1.4rem;
    font-weight: 400;
    margin: 0;
    text-align: start;
}

.ddc-my-donation-detail img {
    width: 100%;
    max-width: 80px;
}

.ddc-donate-now-btn {
    color: #3A98D0;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 0.9rem;
    font-weight: 700;
    text-align: center;
    background-color: #FCFEFF;
    border: none;
    border-radius: 12px;
    width: 100%;
    letter-spacing: 1px;
    padding: 8px 8px;
    box-sizing: border-box;
    cursor: pointer;
}




.dash-yearly-donation-card {
    border-radius: 12px;
    padding: 12px;
    box-sizing: border-box;
    background-color: #4496D2;
    height: 160px;
    width: 100%;
    max-width: 320px;
    min-width: 260px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dyc-details {
    display: flex;
    align-items: center;
    gap: 4px;
}

.dyc-details div {
    display: flex;
    flex-direction: column;
}

.dyc-details span:first-child {
    color: #FFFFFF;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    text-align: start;
}

.dyc-details span:nth-child(2) {
    color: #FFFFFF;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 0.9rem;
    font-weight: 300;
    margin: 0;
    text-align: start;
}

.dyc-progress {
    margin-top: 12px;
}

.dyc-progress div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dyc-progress span {
    color: #FFFFFF;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 0.8rem;
    font-weight: 300;
}


.dashboard-our-campaigns {
    background-color: #FFFFFF;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 12px;
    margin: 0 12px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.dashboard-our-campaigns h2 {
    color: #242020;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 8px 0;
    text-align: start;
}

.dashboard-our-campaigns img {
    width: 100%;
    max-width: 600px;
}