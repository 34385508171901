.tandc {
    background-color: #F6F6F6;
}

.tandc-header {
    background-image: url("../../../public/assets/blog background.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    height: 100%;
    width: 100%;
    min-height: 45vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 10%;
    box-sizing: border-box;
}

.tandc-header h1 {
    color: #F6F6F6;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 2.2rem;
    font-weight: 700;
    margin: 0;
    text-align: center;
}

.tandc-header h5 {
    color: #F6F6F6;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    text-align: center;
}

.tandc-header span {
    color: #4496D2;
}

.tandc-content {
    width: 100%;
    max-width: 1440px;
    padding: 16px 10%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin: 12px auto;
}

.tandc-content article {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.tandc-content h2 {
    color: #242020;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1.4rem;
    font-weight: 700;
    margin: 0;
    text-align: start;
}

.tandc-content p {
    color: #242020;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1.1rem;
    font-weight: 400;
    margin: 0;
    text-align: start;
}

.bottom-tandc {
    background-color: #000925;
    padding: 24px 16px;
    box-sizing: border-box;
}

.bottom-tandc blockquote {
    color: #F6F6F6;
    font-family: 'Schoolbell';
    font-style: normal;
    font-size: 1.1rem;
    font-weight: 500;
    text-align: center;
    margin: 0;
}

.bottom-tandc span {
    color: #4496D2;
}

@media screen and (max-width:576px) {
    .tandc-content {
        padding: 16px 16px;
        box-sizing: border-box;
    }
}