.banner {
    width: 100%;
    height: 100%;
    min-height: 50vh;
    /* max-height: 90vh; */
    background: #ECF7FF;
    /* padding: 16px;
    box-sizing: border-box; */
    display: flex;
    justify-content: flex-end;
}

.banner img {
    width: 100%;
    max-width: 1080px;
    height: auto;
    /* max-height: 100vh; */
    /* object-fit: cover; */
    /* margin: 1vh 0; */
    /* object-fit: cover; */
}

@media screen and (min-width:768px) and (max-width:1024px) {
    .banner {
        width: 100vw;
        background: #ECF7FF;
        min-height: 40vh;
    }

    .banner img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}

@media screen and (min-width:576px) and (max-width:768px) {
    .banner {
        width: 100vw;
        background: #ECF7FF;
        min-height: 40vh;
    }

    .banner img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}

@media screen and (max-width:576px) {
    .banner {
        width: 100vw;
        background: #ECF7FF;
        min-height: 25vh;
    }

    .banner img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}