.footer {
    padding: 40px 16px;
    box-sizing: border-box;
    height: 100%;
    background-color: #ECF7FF;
}

.footer-cont {
    display: flex;
    justify-content: space-evenly;
}

.footer-item {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
}

.footer-item img {
    width: 180px;
}

.footer-item p {
    color: #101010;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    text-align: start;
    margin: 0;
}

.footer-menu-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: flex-start;
    gap: 12px;
}

.footer-menu-list span {
    color: #101010;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    text-align: start;
    margin: 0;
    cursor: pointer;
}

.footer-subscribe-cont {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;
}

.fs-cont label {
    color: #101010;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    text-align: start;
    margin: 0;
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;
}

.fs-input-cont {
    display: flex;
    align-items: center;
    width: 100%;
    border: 2px solid #4496D2;
    border-radius: 30px;
    width: 100%;
    height: 45px;
}

.fs-input-cont input {
    background-color: transparent;
    color: #8E8787;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    text-align: start;
    margin: 0;
    border: none;
    outline: none;
    width: 100%;
    padding: 0 12px;
    box-sizing: border-box;
}

.fs-input-cont span {
    background-color: #4496D2;
    color: #ECF7FF;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1.2rem;
    font-weight: 600;
    width: 50px;
    height: 100%;
    border-radius: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    cursor: pointer;
}

.right-bottom-fc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
}

.footer-location {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.footer-location h6 {
    color: #101010;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 0.9rem;
    font-weight: 600;
    margin: 0;
    text-align: start;
    display: flex;
    align-items: center;
    gap: 5px;
}

.footer-location span {
    color: #4496D2;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1.1rem;
    font-weight: 600;
    text-align: start;
    margin: 0;
}

.footer-share-icon {
    display: flex;
    align-items: center;
    gap: 6px;
}

.footer-share-icon span {
    background-color: #4496D2;
    color: #ECF7FF;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 0.9rem;
    font-weight: 600;
    text-align: center;
    margin: 0;
    border-radius: 50%;
    padding: 8px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.flink {
    text-decoration: none;
    color: #101010;
}

@media screen and (max-width:768px) {
    .footer-cont {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        gap: 16px;
    }

    .right-bottom-fc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 12px;
    }

    .footer-location {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .footer-location h6 {
        color: #101010;
        font-family: 'Nunito';
        font-style: normal;
        font-size: 0.9rem;
        font-weight: 600;
        margin: 0;
        text-align: start;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .footer-menu-list span {
        color: #101010;
        font-family: 'Nunito';
        font-style: normal;
        font-size: 1rem;
        font-weight: 600;
        text-align: center;
        margin: 0;
        cursor: pointer;
    }
}