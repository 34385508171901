.payment-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    /* height: 320px; */
}

.mid-logo {
    width: 140px;
}

.mid-logo img {
    width: 100%;
}

.payment-input-fields {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.payment-input-fields label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #101010;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
}

.payment-input-fields input {
    background-color: transparent;
    width: 100%;
    height: 40px;
    outline: none;
    border: none;
    font-size: 1rem;
    border: 1px solid #494949;
    border-radius: 8px;
    margin-left: 5px;
    padding: 0 12px;
    box-sizing: border-box;
    color: #101010;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
}

.payment-input-fields select {
    background-color: transparent;
    width: 100%;
    height: 40px;
    outline: none;
    border: none;
    font-size: 1rem;
    border: 1px solid #494949;
    border-radius: 8px;
    margin-left: 5px;
    padding: 0 12px;
    box-sizing: border-box;
    color: #101010;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
}

.payment-donate-btn {
    color: #F6F6F6;
    font-size: 1rem;
    font-weight: 600;
    font-family: Quicksand;
    border: none;
    text-align: center;
    box-sizing: border-box;
    background-color: #4496D2;
    border-radius: 20px;
    width: 100%;
    height: 50px;
    cursor: pointer;
    /* margin-top: 40px; */
}

.payment-donate-btn:hover {
    opacity: 0.8;
}