.b-r-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

}

.keyword-input-box {
    width: 80%;
    height: 50px;
    display: flex;
    background-color: #FFF;
    border-radius: 5px;
    overflow: hidden;
}

.keyword-input-box input {
    height: 100%;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    font-size: 1rem;
    outline: 0;
    border: none;
    color: #101010;
    font-family: Nunito;
    font-size: 1rem;
    font-weight: 400;
}

.keyword-input-box input::placeholder {
    font-family: Nunito;
    font-size: 1rem;
    font-weight: 400;
    text-align: left;

}

.input-right-box {
    height: 100%;
    width: 60px;
    border-radius: 5px;
    background: #4496D2;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 1.5rem;
    font-weight: 500;
}


.b-r-categories {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 80%;
}

.b-r-c-head {
    color: #101010;
    font-family: Nunito;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;

}

.b-r-c-item {
    background: #FFFFFF;
    color: #101010;
    font-family: Nunito;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    border-radius: 10px;
    padding: 12px;
    box-sizing: border-box;
}

.b-r-recent-posts {
    margin-top: 40px;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.b-r-rp-head {
    font-family: Nunito;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
}

.b-r-rp-item {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 10px;
    padding: 8px;
    box-sizing: border-box;
    background-color: #FFF;
    border-radius: 10px;
}

.rp-left img {
    width: 100px;
    height: 100%;
    border-radius: 10px;
}


.rp-right p {
    font-family: Nunito;
    color: #101010;
    font-size: 0.9rem;
    font-weight: 700;
    text-align: left;
    margin: 0;
}

.rp-r-top span {
    font-family: Nunito;
    font-size: 10px;
    font-weight: 700;
    text-align: left;
}

.b-r-popular-tags {
    margin-top: 40px;
    width: 80%;
}

.b-r-pt-head {
    font-family: Nunito;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    color: #101010;
}

.b-r-pt-item {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    margin: 10px 0px;
    font-family: Nunito;
    font-size: 1rem;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: #494949;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

@media screen and (max-width:576px) {

    .keyword-input-box {
        width: 95%;
        height: 50px;
        display: flex;
        background-color: #FFF;
        border-radius: 5px;
        overflow: hidden;
    }

    .b-r-categories {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 95%;
    }

    .b-r-recent-posts {
        margin-top: 40px;
        width: 95%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .b-r-popular-tags {
        margin-top: 40px;
        width: 95%;
    }

}