.privacy-policy {
    background-color: #F6F6F6;
}

.privacy-policy {
    background-color: #F6F6F6;
}

.privacy-policy-header {
    background-image: url("../../../public/assets/blog background.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    height: 100%;
    width: 100%;
    min-height: 45vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 10%;
    box-sizing: border-box;
}

.privacy-policy-header h1 {
    color: #F6F6F6;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 2.2rem;
    font-weight: 700;
    margin: 0;
    text-align: center;
}

.privacy-policy-header h5 {
    color: #F6F6F6;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    text-align: center;
}

.privacy-policy-header span {
    color: #4496D2;
}

.privacy-policy-content {
    width: 100%;
    max-width: 1440px;
    padding: 16px 10%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin: 12px auto;
}

.privacy-policy-content article {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.privacy-policy-content h2 {
    color: #242020;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1.4rem;
    font-weight: 700;
    margin: 0;
    text-align: start;
}

.privacy-policy-content p {
    color: #242020;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1.1rem;
    font-weight: 400;
    margin: 0;
    text-align: start;
    /* display: flex; */
    /* align-items: center; */
    gap: 4px;
    line-height: 1.5rem;
}

.privacy-policy-content a {
    color: #4496D2;
    font-family: 'Nunito';
    text-decoration: none;
}

.privacy-policy-content ul {
    color: #242020;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1.1rem;
    font-weight: 400;
    margin: 0;
    text-align: start;
}

.bottom-privacy-policy {
    background-color: #000925;
    padding: 24px 16px;
    box-sizing: border-box;
}

.bottom-privacy-policy h6 {
    color: #F6F6F6;
    font-family: 'Schoolbell';
    font-style: normal;
    font-size: 1.1rem;
    font-weight: 500;
    text-align: center;
    margin: 0;
}

.bottom-privacy-policy span {
    color: #4496D2;
}

@media screen and (max-width:576px) {
    .privacy-policy-content {
        padding: 16px;
        box-sizing: border-box;
    }
}