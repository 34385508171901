.donate {
    background-color: #F6F6F6;
}

.donate-header {
    background-image: url("../../../public/assets/blog background.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    height: 100%;
    width: 100%;
    min-height: 40vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 10%;
    box-sizing: border-box;
}

.donate-header h1 {
    color: #F6F6F6;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 2.2rem;
    font-weight: 700;
    margin: 0;
    text-align: center;
}

.donate-header h5 {
    color: #F6F6F6;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    text-align: center;
}

.donate-header span {
    color: #4496D2;
}

.donate-cont {
    background-image: url("../../../public/assets/donate.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    height: 100%;
    width: 100%;
    min-height: 45vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding: 30px 10%; */
    padding: 30px 16px;
    box-sizing: border-box;
    align-items: center;
}

.donate-card {
    background-color: #FFF;
    border-radius: 20px;
    width: 100%;
    max-width: 500px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /* gap: 12px; */
}

.donate-payment-tab-cont-head {
    color: #111111;
    font-family: 'Nunito';
    font-style: normal;
    margin: 12px 0;
    text-align: start;
    font-weight: 700;
}

.donate-field-label {
    display: flex;
    align-items: center;
    gap: 16px;
    border-bottom: 2px solid #494949;
    padding: 8px 0;
    box-sizing: border-box;
    border-radius: 2px;
    color: #111111;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 700;
    text-align: start;
}

.donate-fields {
    border-bottom: 2px solid #494949;
    padding-bottom: 12px;
    box-sizing: border-box;
    border-radius: 2px;
    color: #111111;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 700;
    text-align: start;
}

.donate-amount-label {
    display: flex;
    align-items: center;
    padding: 8px 0;
    box-sizing: border-box;
    gap: 16px;
    border-radius: 2px;
    color: #111111;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 700;
    text-align: start;
}

.donate-card label {
    display: flex;
    /* gap: 16px; */
    color: #111111;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    text-align: start;
}


.donate-input-fields {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.donate-input-fields label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #101010;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
}

.donate-input-fields input {
    /* background-color: transparent; */
    width: 100%;
    height: 40px;
    outline: none;
    border: none;
    font-size: 1rem;
    border: 1px solid #494949;
    border-radius: 8px;
    margin-left: 5px;
    padding: 0 12px;
    box-sizing: border-box;
    color: #101010;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    background-color: #FFFFFF;
}

.donate-amount-label input {
    /* background-color: transparent; */
    width: 100%;
    height: 40px;
    outline: none;
    border: none;
    font-size: 1rem;
    border: 1px solid #494949;
    border-radius: 8px;
    margin-left: 5px;
    padding: 0 12px;
    box-sizing: border-box;
    color: #101010;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    background-color: #FFFFFF;
}

.donate-card select {
    /* background-color: transparent; */
    width: 100%;
    height: 40px;
    outline: none;
    border: none;
    font-size: 1rem;
    border: 1px solid #494949;
    border-radius: 8px;
    margin-left: 5px;
    padding: 0 12px;
    box-sizing: border-box;
    color: #101010;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    background-color: #FFFFFF;
}

.donate-btn {
    color: #F6F6F6;
    font-size: 1rem;
    font-weight: 600;
    font-family: Quicksand;
    border: none;
    text-align: center;
    box-sizing: border-box;
    background-color: #4496D2;
    border-radius: 20px;
    width: 100%;
    height: 50px;
    cursor: pointer;
    margin-top: 10px;
}

.donate-btn:hover {
    opacity: 0.8;
}

.bottom-donate {
    background-color: #000925;
    padding: 24px 16px;
    box-sizing: border-box;
}

.bottom-donate h6 {
    color: #F6F6F6;
    font-family: 'Schoolbell';
    font-style: normal;
    font-size: 1.1rem;
    font-weight: 500;
    text-align: center;
    margin: 0;
}

.bottom-donate span {
    color: #4496D2;
}

/* tab container */
.donate-payment-tab-btns {
    /* background-color: #242020; */
    display: flex;
    justify-content: flex-start;
    align-items: start;
    gap: 8px;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0;
    transform: translateY(1px);
    /* margin: 10px auto; */
    /* border-radius: 30px; */
    /* height: 42px; */
}

.donate-tab-btn {
    border: none;
    background: rgba(212, 223, 230, 0.34);
    color: rgba(16, 16, 16, 0.59);
    font-family: 'Nunito';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    /* line-height: 21px; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 10px; */
    cursor: pointer;
    padding: 8px 12px;
    box-sizing: border-box;
    outline: none;
    /* width: 100%; */
    border-radius: 12px;
    height: 38px;
}

/* active tab */
.donate-tab-btn.active {
    background: #EBF7FF;
    border: 1px solid #3A98D0;
    border-bottom: none;
    color: #101010;
    border-radius: 12px 12px 0 0;
    height: 42px;
    font-weight: 600;
}

.donate-payment-tab-cont {
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    background-color: rgba(235, 247, 255, 1);
    border: 1px solid #3A98D0;
    margin: 0;
    border-radius: 0px 12px 12px 12px;
}

.donate-payment-tab-cont img {
    width: 60%;
    border-radius: 12px;
}

.dp-qr-code-cont{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.dp-upi-id {
    color: #111111;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1.1rem;
    font-weight: 700;
    text-align: start;
}


@media screen and (max-width:480px) {
    .donate-field-label {
        display: flex;
        flex-direction: column;
        gap: 8px;
        border-bottom: 2px solid #494949;
        padding-bottom: 12px;
        box-sizing: border-box;
        border-radius: 2px;
        color: #111111;
        font-family: 'Nunito';
        font-style: normal;
        font-size: 1rem;
        font-weight: 700;
        text-align: start;
    }

    .donate-amount-label {
        display: flex;
        flex-direction: column;
        gap: 4px;
        border-radius: 2px;
        color: #111111;
        font-family: 'Nunito';
        font-style: normal;
        font-size: 1rem;
        font-weight: 700;
        text-align: start;
    }

    .donate-input-fields {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}