.sidebar-layout {
    display: flex;
    height: 100vh;
}

.sl-outlet{
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    overflow-y: auto;
}