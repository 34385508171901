.App {
  text-align: center;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.primary-button {
  color: #F6F6F6;
  font-size: 1rem;
  font-weight: 600;
  font-family: Quicksand;
  border: none;
  text-align: center;
  box-sizing: border-box;
  background-color: #4496D2;
  border-radius: 20px;
  /* width: 100%;
  max-width: 200px; */
  height: 42px;
  padding: 4px 8px;
  box-sizing: border-box;
  cursor: pointer;
  /* margin-top: 10px; */
}

.primary-button:hover {
  opacity: 0.8;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */