.blog {
    background-color: #F6F6F6;
}

.blog-header {
    background-image: url("../../../public/assets/blog background.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    height: 100%;
    width: 100%;
    min-height: 45vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 10%;
    box-sizing: border-box;
}

.blog-header h1 {
    color: #F6F6F6;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 2.2rem;
    font-weight: 700;
    margin: 0;
    text-align: center;
}

.blog-header h5 {
    color: #F6F6F6;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    text-align: center;
}

.blog-header span {
    color: #4496D2;
}


.blog-mid {
    display: flex;
}

.b-m-left {
    padding: 20px 40px;
    box-sizing: border-box;
    width: 70%;
}

.b-m-right {
    padding: 20px;
    box-sizing: border-box;
    width: 30%;
}


.b-m-l-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 16px;
    /* padding: 16px; */
    box-sizing: border-box;
}

.b-m-l-card {
    border: 1px solid #4496D2;
    border-radius: 20px;
    background-color: #FFFFFF;
    width: 100%;
    max-width: 250px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.b-date {
    padding: 12px;
    box-sizing: border-box;
}

.top-blog-card {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 8px;
}

.top-blog-card span {
    background-color: #4496D2;
    color: #F6F6F6;
    border-radius: 16px;
    padding: 6px 12px;
    box-sizing: border-box;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
    margin: 0;
    text-transform: uppercase;
}

.blog-image img {
    width: 100%;
    border-radius: 20px;
}

.blog-reviews {
    display: flex;
    gap: 12px;
}

.blog-reviews span {
    color: #6C6666;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 500;
    text-align: start;
    margin: 0;
}

.blog-headline p {
    color: #242020;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    text-align: start;
    margin: 0;
}

.blog-read-more-cont {
    border-top: 1px solid #D2F5FF;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 50px;
    /* padding: 8px 0; */
    box-sizing: border-box;
    gap: 12px;
}

.blog-read-more-cont button {
    color: #242020;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    text-align: center;
    border: none;
    background-color: transparent;
}

.blog-read-more-cont span {
    color: #6C6666;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #ECF7FF;
    height: 100%;
    width: 60px;
    border-radius: 20px 0 20px 20px;
    cursor: pointer;
}

.bottom-blog {
    background-color: #000925;
    padding: 24px 16px;
    box-sizing: border-box;
}

.bottom-blog h6 {
    color: #F6F6F6;
    font-family: 'Schoolbell';
    font-style: normal;
    font-size: 1.1rem;
    font-weight: 500;
    text-align: center;
    margin: 0;
}

.bottom-blog span {
    color: #4496D2;
}

@media screen and (max-width:768px) {
    .blog-mid {
        display: flex;
        flex-direction: column;
    }

    .b-m-left {
        padding: 20px 16px;
        box-sizing: border-box;
        width: 100%;
    }
    
    .b-m-right {
        padding: 16px;
        box-sizing: border-box;
        width: 100%;
    }

    .b-m-l-card {
        border: 1px solid #4496D2;
        border-radius: 20px;
        background-color: #FFFFFF;
        width: 100%;
        max-width: 300px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}