.news {
    padding: 16px;
    box-sizing: border-box;
    background-color: #F6F6F6;
}

.news-header {
    margin-bottom: 5%;
}

.news-header h6 {
    color: #4496D2;
    text-align: center;
    font-family: 'Schoolbell';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    margin: 0px;
}

.news-header h1 {
    color: #242020;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    margin: 0;
}

.news-cards-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 16px;
    /* padding: 16px; */
    box-sizing: border-box;
}

.news-card {
    border: 1px solid #4496D2;
    border-radius: 20px;
    background-color: #FFFFFF;
    width: 100%;
    max-width: 300px;
    overflow: hidden;
}

.top-news-card {
    padding: 12px;
    box-sizing: border-box;
}

.news-date {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 8px;
}

.news-date span {
    background-color: #4496D2;
    color: #F6F6F6;
    border-radius: 16px;
    padding: 6px 12px;
    box-sizing: border-box;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
    margin: 0;
    text-transform: uppercase;
}

.news-image img {
    width: 100%;
    border-radius: 20px;
}

.news-reviews {
    display: flex;
    gap: 12px;
}

.news-reviews span {
    color: #6C6666;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 500;
    text-align: start;
    margin: 0;
}

.news-headline p {
    color: #242020;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    text-align: start;
    margin: 0;
}

.news-read-more-cont {
    border-top: 1px solid #D2F5FF;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 50px;
    /* padding: 8px 0; */
    box-sizing: border-box;
    gap: 12px;
}

.news-read-more-cont button {
    color: #242020;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    text-align: center;
    border: none;
    background-color: transparent;
}

.news-read-more-cont span {
    color: #6C6666;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #ECF7FF;
    height: 100%;
    width: 60px;
    border-radius: 20px 0 20px 20px;
    cursor: pointer;
}