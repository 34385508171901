.contact {
    display: flex;
    justify-content: center;
    background-color: #F6F6F6;
    padding-top: 30px;
}

.contact-cont {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 10vh;
    background-color: #ECF7FF;
    width: 100%;
    max-width: 1024px;
    border-radius: 16px 16px 0 0;
    padding: 30px;
    box-sizing: border-box;
    gap: 16px;
}

.contact-img-cont img {
    width: 100%;
    max-width: 300px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.contact-form form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.contact-form h1 {
    color: #00054B;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 2.5rem;
    font-weight: 700;
    text-align: start;
    margin: 0;
    margin-bottom: 16px;
}

.contact-form label {
    color: rgba(0, 2, 75, 0.5);
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    text-align: start;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.contact-form label input {
    background-color: transparent;
    color: rgba(0, 2, 75, 0.5);
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    text-align: start;
    margin: 0;
    border: none;
    border-bottom: 2px solid #00024B;
    outline: none;

}

.contact-btn {
    width: 100%;
    height: 45px;
    border: none;
    border-radius: 20px;
    background-color: #4496D2;
    color: #FFF;
    cursor: pointer;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    font-family: 'Nunito';
    font-style: normal;
}

.contact-btn:hover {
    opacity: 0.8;
}

.contact-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
}

.contact-info h6 {
    color: #4496D2;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    text-align: start;
    margin: 0;
}

.contact-info span {
    color: #101010;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    text-align: start;
    margin: 0;
}

.contact-icon {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 30px;
}

.contact-icon span {
    color: #101010;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 2rem;
    font-weight: 600;
    text-align: start;
    margin: 0;
}

.contact-link {
    text-decoration: none;
    color: #101010;
}

@media screen and (max-width:768px) {
    .contact-cont {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        min-height: 10vh;
        background-color: #ECF7FF;
        width: 100%;
        max-width: 1024px;
        border-radius: 16px 16px 0 0;
        padding: 30px;
        box-sizing: border-box;
    }
}