.payment-success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    gap: 16px;
    padding: 16px;
    box-sizing: border-box;
    height: 100vh;
}

.payment-success img {
    width: 180px;
}

.payment-success h1 {
    color: #101010;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 2.2rem;
    font-weight: 600;
    margin: 0;
}

.payment-success span {
    color: #101010;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
}

.payment-success button {
    color: #F6F6F6;
    font-size: 1rem;
    font-weight: 600;
    font-family: Quicksand;
    border: none;
    background-color: #4496D2;
    border-radius: 20px;
    width: 140px;
    height: 50px;
    cursor: pointer;
    text-align: center;
}

.payment-success button:hover {
    opacity: 0.8;
}