.left-hdpro {
    background: #FCFEFF;
    width: 260px;
    /* min-width: 200px;
    max-width: 250px; */
    padding: 16px;
    box-sizing: border-box;
    height: 100vh;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.left-hdpro-cont {
    display: flex;
    flex-direction: column;
    text-align: start;
}

.hdpro-back-btn {
    color: #FFF;
    font-size: 1.2rem;
    border: none;
    background-color: transparent;
}

.krinvilogo-img-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 20px 0; */
    /* padding-bottom: 10px; */
    box-sizing: border-box;
    /* transform: translateY(-20px); */
    margin-bottom: 8px;
}

.krinvilogo-img-cont img {
    width: 100%;
    max-width: 120px;
}


.krinvilogo-img-cont h3 {
    color: var(--d-1-ffe-1, #FFF);
    font-family: Poppins;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0;
}

.hlc-mid-cont {
    display: flex;
    flex-direction: column;
    /* gap: 5px; */
}

.hdpro-text-list {
    display: flex;
    /* width: 238px; */
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
    gap: 5px;
    border-radius: 14px;
    /* background: var(--27-b-357, #27B357); */
    background: transparent;
    color: #242020;
    font-family: Nunito;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.4px;
    border: none;
    cursor: pointer;
}

.hdpro-text-list img {
    width: 20px;
    height: 20px;
}

/* Add this to your CSS file */
.hdpro-text-list.active {
    background: #4496D2;
    color: #FFFFFF;
}


.hdpro-profile-text {
    color: #242020;
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 41px;
    letter-spacing: -0.4px;
}

.hleftcard-link {
    text-decoration: none;
}

.top-hleft-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hlc-bar-btn {
    display: none;
}





.hlc-mid-bottom-combine {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    /* background-color: aqua; */
    height: 75vh;
}

.hdpro-logoutbtn {
    display: flex;
    /* width: 238px; */
    width: 180px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 14px;
    /* background: var(--27-b-357, #27B357); */
    color: var(--d-1-ffe-1, #FFF);
    font-family: Poppins;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.4px;
    border: 2px solid #27B357;
    background: transparent;
    cursor: pointer;
}

.hdpro-logo-img-cont img{
    width: 100%;
    max-width: 120px;
    display: none;
}


@media screen and (max-width: 800px) {

    .hdpro-logo-img-cont img{
        width: 100%;
        max-width: 120px;
        display: block;
    }

    .left-hdpro {
        /* background: var(--101010, #101010); */
        background: #FCFEFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        width: 100%;
        min-width: none;
        max-width: none;
        padding: 15px;
        box-sizing: border-box;
        height: 100%;
        z-index: 99;
        position: relative;
    }

    .bottom-sidnav-hlc {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        transition: transform 0.3s;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        overflow-x: scroll;
        overflow-y: scroll;
        width: 70%;
        max-width: 240px;
        -webkit-animation: mobile-slide ease-out .2s;
        -moz-animation: mobile-slide ease-out .2s;
        animation: mobile-slide ease-out .2s;
        position: fixed;
        /* background: #000000; */
        background: #FCFEFF;
        display: block;
        padding-left: 0;
        padding: 30px 10px 10px 10px;
        box-sizing: border-box;
    }

    .hlc-bar-btn {
        display: block;
        border: none;
        background-color: transparent;
        font-size: 1.5rem;
        color: #242020;
    }

    .navbar-toggle button {
        display: block;
        border: none;
        background-color: transparent;
        font-size: 1.5rem;
        color: #242020;
    }

    .items {
        display: none;
        overflow: auto;
    }

    .items.open {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        transition: transform 0.3s;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        overflow-x: scroll;
        overflow-y: scroll;
        /* width: 220px; */
        -webkit-animation: mobile-slide ease-out .2s;
        -moz-animation: mobile-slide ease-out .2s;
        animation: mobile-slide ease-out .2s;
        position: fixed;
        background: #101010;
        color: #FFF;
        display: block;
        padding-left: 0;
    }

    .items.open h4 {
        font-size: 1rem;
        display: flex;
        align-items: center;
        color: #3e4152;
        text-decoration: none;
        transition: background-color 0.3s ease-in-out;
        text-align: start;
        height: 8vh;
        justify-content: space-between;
        margin: 0;
        padding: 0px 12px;
        box-sizing: border-box;
    }

    .hdpro-logoutbtn {
        width: 100%;
        padding: 10px;
        gap: 10px;

    }
}